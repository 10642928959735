<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import NewsRender from "@/components/news/news-render.vue";

/**
 * News Component
 */
export default {
  computed: {
    dateUtil() {
      return dateUtil
    }
  },
  components: {
    NewsRender,
    Layout,
    PageHeader
  },

  data() {
    return {
      newsList: [],
      users: new Map(),
    };
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('menuitems.dashboard.list.news'),
          active: true
        }
      ]
    },

    async loadNews() {
      try {
        const {data} = await axios.get(`/news/list-enabled`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.newsList = data
      } catch (error) {
        console.log(error)
      }
    },

    getNewsUser(userId) {
      if (!this.users) {
        return null;
      }

      return this.users.get(userId) || null;
    },

    async loadUsers() {
      if (this.newsList.length === 0) {
        return;
      }

      const ids = [];
      for (let news of this.newsList) {
        ids.push(news.author);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const {data} = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(data.map((obj) => [obj.id, obj]));
    },

    getUser(userId) {
      if (!this.users) {
        return null;
      }

      return this.users.get(userId) || null;
    },

  },

  async created() {
    try {
      await this.loadNews()
      await this.loadUsers()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('menuitems.dashboard.list.news')"/>
    <div class="row">
      <news-render
          v-for="(news, index) in newsList"
          :key="index"
          :news="news"
          :news-user="users"
          :isFirstNews="index !== 0"
      />
    </div>
  </Layout>
</template>